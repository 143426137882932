<template>
  <div style="height: 100%" class="single-book">
    <div class="single-book-parent" style="height: 100%" ref="scrollable" v-bar>
      <div>
        <v-container>
          <div v-if="!loading">
            <div v-if="!isEditMode && !newBook" class="py-5">
              <v-card tile class="pa-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="px-2">
                    <v-card-text class="pb-0 text-right">
                      <v-badge
                        dot
                        inline
                        left
                        tile
                        :color="statusColors[bookData.status]"
                        class="stock-badge"
                      >
                        <div
                          :class="[
                            'force-single-line',
                            `${statusColors[bookData.status]}--text`
                          ]"
                        >
                          <span v-if="bookData.status === 1">In stock</span>
                          <span v-else-if="bookData.status === 2">
                            {{
                              bookData.distributedTo
                                ? `# ${bookData.distributedTo.memberNumber} ${bookData.distributedTo.name}`
                                : "Out of stock"
                            }}
                          </span>
                          <span v-else>Removed</span>
                        </div>
                      </v-badge>
                      <v-btn
                        v-if="!isSubLibrary"
                        @click="gotoNewBookPage"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Add new book</v-btn
                      >
                      <v-btn
                        @click="showQrCodeModal"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Qr code</v-btn
                      >
                      <v-btn
                        v-if="!isSubLibrary"
                        @click="(isEditMode = true), (isCustomCategory = false)"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Edit</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Book Number </v-card-text>
                    <v-card-title class="pt-0">
                      # {{ bookData.bookNumber }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Name </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.name }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Category </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.type }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Author </v-card-text>
                    <v-card-title
                      :class="[
                        'pt-0',
                        bookData.author ? '' : 'not-assigned grey--text'
                      ]"
                    >
                      {{ bookData.author ? bookData.author : "Not assigned" }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Price </v-card-text>
                    <v-card-title class="pt-0">
                      ₹ {{ bookData.price }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Date Added </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.dateAdded | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="bookData.language" cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Language </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.language }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="bookData.isbn" cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> ISBN </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.isbn }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="bookData.stockNo" cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Stock Number </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.stockNo }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.publisher"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Publisher </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.publisher }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.catalogueNo"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Catalogue Number </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.catalogueNo }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Distribution Count </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.distributedCount }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Shelf Number </v-card-text>
                    <v-card-title class="pt-0">
                      {{
                        bookData.shelfNumber
                          ? `S${parseInt(bookData.shelfNumber)}`
                          : "N/A"
                      }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.invoiceDate"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Invoice Date </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.invoiceDate | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.invoiceNumber"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Invoice Number </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.invoiceNumber }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="bookData.remarks" cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Remarks </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.remarks }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="bookData.source" cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Source of the book </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.source }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.dateRemoved && bookData.status === 3"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Date Removed </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.dateRemoved | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="bookData.removedReason && bookData.status === 3"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Removed Reason </v-card-text>
                    <v-card-title class="pt-0">
                      {{ bookData.removedReason }}
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else class="py-5">
              <v-card tile class="pa-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="px-2">
                    <v-card-text class="pb-0 text-right">
                      <v-badge
                        dot
                        inline
                        left
                        tile
                        :color="statusColors[editModeBookData.status]"
                        class="stock-badge"
                      >
                        <div
                          :class="[
                            'force-single-line',
                            `${statusColors[editModeBookData.status]}--text`
                          ]"
                        >
                          <span v-if="editModeBookData.status === 1"
                            >In stock</span
                          >
                          <span v-else-if="editModeBookData.status === 2">
                            {{
                              editModeBookData.distributedTo
                                ? `# ${editModeBookData.distributedTo.memberNumber} ${editModeBookData.distributedTo.name}`
                                : "Out of stock"
                            }}
                          </span>
                          <span v-else>Removed</span>
                        </div>
                      </v-badge>
                      <v-btn
                        :disabled="!hasChanged"
                        v-if="!newBook"
                        @click="updateData"
                        x-small
                        tile
                        color="primary"
                        class="ml-2 mb-2"
                        >Update</v-btn
                      >
                      <v-btn
                        v-else
                        @click="saveNewData"
                        x-small
                        tile
                        color="primary"
                        class="ml-2 mb-2"
                        >Save</v-btn
                      >
                      <v-btn
                        v-if="!newBook"
                        @click="isEditMode = false"
                        x-small
                        tile
                        class="ml-2 mb-2"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-else-if="isBackPossible"
                        @click="goBackWard"
                        x-small
                        tile
                        class="ml-2 mb-2"
                        >Cancel</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-form
                  autocomplete="off"
                  ref="form"
                  v-model="formValid"
                  lazy-validation
                >
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Book Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          disabled
                          :loading="newBook && nextNumberLoading"
                          :value="
                            newBook
                              ? `# ${nextNumber}`
                              : `# ${editModeBookData.bookNumber}`
                          "
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Book Name </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v.trim().length && v.trim().length <= 75
                                ? true
                                : 'Required (Max: 75 chars)'
                          ]"
                          class="py-0 my-0"
                          v-model="editModeBookData.name"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0 d-flex justify-space-between">
                        Category
                        <v-btn x-small tile @click="toggleCustomCategory">
                          {{
                            !isCustomCategory
                              ? "Add custom category"
                              : "Select from dropdown"
                          }}
                        </v-btn>
                      </v-card-text>
                      <v-card-title class="pt-0">
                        <vc-data-autocomplete
                          ref="categoryInput"
                          api="/book/type"
                          map-value="name"
                          emit-on-search
                          v-model="editModeBookData.type"
                          :defaultItems="bookCategories"
                          :sortItem="(a, b) => (a > b ? 1 : -1)"
                          class="py-0 my-0"
                          dense
                          v-if="!isCustomCategory"
                          :rules="[(v) => (v && v.length ? true : 'Required')]"
                        />
                        <v-text-field
                          v-else
                          :rules="[
                            (v) =>
                              v.trim().length && v.trim().length <= 50
                                ? true
                                : 'Required (Max: 50 chars)'
                          ]"
                          ref="categoryInput"
                          class="py-0 my-0"
                          v-model="editModeBookData.type"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Author </v-card-text>
                      <v-card-title class="pt-0">
                        <vc-data-combobox
                          api="/author"
                          :rules="[
                            (v) =>
                              v.name &&
                              v.name.trim().length &&
                              v.name.trim().length <= 50
                                ? true
                                : 'Required (Max: 50 chars)'
                          ]"
                          hide-selected
                          emit-on-search
                          v-model="editModeBookData.author"
                          item-text="name"
                          item-value="name"
                          class="py-0 my-0"
                          dense
                        >
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </vc-data-combobox>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Price </v-card-text>
                      <v-card-title class="pt-0">
                        <v-currency-field
                          prefix="₹"
                          :rules="[
                            (v) =>
                              v.toString().trim().length
                                ? true
                                : 'Enter a valid price'
                          ]"
                          class="py-0 my-0"
                          type="number"
                          v-model="editModeBookData.price"
                        ></v-currency-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Date Added </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="dateAddedMenuRef"
                          v-model="dateAddedMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeBookData.dateAdded"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :rules="[
                                (v) =>
                                  v && v.trim().length ? true : 'Required'
                              ]"
                              class="py-0 my-0"
                              :value="formatDate(editModeBookData.dateAdded)"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editModeBookData.dateAdded"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateAddedMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dateAddedMenuRef.save(
                                  editModeBookData.dateAdded
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Language </v-card-text>
                      <v-card-title class="pt-0">
                        <v-autocomplete
                          dense
                          emit-on-search
                          class="py-0 my-0"
                          v-model="editModeBookData.language"
                          :items="languages"
                          item-text="name"
                          item-value="name"
                          :item-disabled="(item) => item.name === '_'"
                          clearable
                        >
                          <template slot="item" slot-scope="data">
                            <div v-if="data.item.name !== '_'">
                              {{ data.item.name }}
                            </div>
                            <template v-else>
                              Other Languages
                              <v-divider />
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> ISBN </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length < 10
                                ? 'ISBN number cannont be less than 10 digits'
                                : true
                          ]"
                          v-mask="'#############'"
                          v-model="editModeBookData.isbn"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Stock Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 20
                                ? 'Max: 20 chars'
                                : true
                          ]"
                          class="py-0 my-0"
                          v-mask="'#############'"
                          v-model="editModeBookData.stockNo"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Publisher </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 50
                                ? 'Max: 50 chars'
                                : true
                          ]"
                          class="py-0 my-0"
                          v-model="editModeBookData.publisher"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Catalogue Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 20
                                ? 'Max: 20 chars'
                                : true
                          ]"
                          class="py-0 my-0"
                          v-model="editModeBookData.catalogueNo"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col v-if="!newBook" cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0">
                        Distribution Count
                      </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          disabled
                          :value="editModeBookData.distributedCount"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Shelf Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          prefix="S"
                          class="py-0 my-0 shelf-number-input"
                          :rules="[
                            (v) =>
                              parseInt(v) !== 0
                                ? true
                                : 'Shelf number cant be zero'
                          ]"
                          v-mask="'####'"
                          v-model="editModeBookData.shelfNumber"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Invoice Date </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="invoiceDateMenuRef"
                          v-model="invoiceDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeBookData.invoiceDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="py-0 my-0"
                              :value="formatDate(editModeBookData.invoiceDate)"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editModeBookData.invoiceDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="invoiceDateMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.invoiceDateMenuRef.save(null)"
                            >
                              Clear
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.invoiceDateMenuRef.save(
                                  editModeBookData.invoiceDate
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Invoice Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 30
                                ? 'Max: 30 chars'
                                : true
                          ]"
                          v-model="editModeBookData.invoiceNumber"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Remarks </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 150
                                ? 'Max: 150 chars'
                                : true
                          ]"
                          v-model="editModeBookData.remarks"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0">
                        Source of the book
                      </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 50
                                ? 'Max: 50 chars'
                                : true
                          ]"
                          v-model="editModeBookData.source"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0">
                        Book Removed from library
                      </v-card-text>
                      <v-card-title class="pt-2">
                        <v-btn-toggle v-model="bookRemoved" mandatory tile>
                          <v-btn small> </v-btn>
                          <v-btn
                            small
                            :color="bookRemoved === 1 ? 'error' : 'normal'"
                            :disabled="editModeBookData.status === 2"
                          >
                            <v-icon>mdi-check-bold</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </v-card-title>
                    </v-col>
                    <v-col v-if="bookRemoved" cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Date Removed </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="dateRemovedMenuRef"
                          v-model="dateRemovedMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeBookData.dateRemoved"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="py-0 my-0"
                              :value="formatDate(editModeBookData.dateRemoved)"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editModeBookData.dateRemoved"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateRemovedMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dateRemovedMenuRef.save(null)"
                            >
                              Clear
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dateRemovedMenuRef.save(
                                  editModeBookData.dateRemoved
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col v-if="bookRemoved" cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Removed Reason </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 150
                                ? 'Max: 150 chars'
                                : true
                          ]"
                          v-model="editModeBookData.removedReason"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </div>
          </div>
          <div class="py-5" v-else>
            <SingleBookSkeltonLoader />
          </div>
        </v-container>
      </div>
    </div>
    <BookQrCode v-model="showQrCode" :bookId="qrCodeBook" />
  </div>
</template>

<script>
import { useSingleBook } from "@/modules";
import SingleBookSkeltonLoader from "@/components/SingleBookSkeltonLoader";
import BookQrCode from "@/components/BookQrCode";

export default {
  setup: useSingleBook,
  components: {
    SingleBookSkeltonLoader,
    BookQrCode
  }
};
</script>
